import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Container from './Container'
import Dropdown from './Dropdown'
import Article from './Article'
import slugify from 'slugify'

const ProductFAQ = ({ product, setProduct, item, setItem, categories, setCategories }) => {

    const [searchKey, setSearchKey] = useState("")
    const [productFilt, setProductFilt] = useState([])
    useEffect(() => setProductFilt(product), [product])
    useEffect(() => search(searchKey), [searchKey])

    const search = key => {
        if(key){
            const filtered = {
                ...product,
                categories: product.categories.map(cat => ({
                    ...cat,
                    articles: cat.articles.filter(art => art.name.toLowerCase().includes(key.toLowerCase()))
                }))
            }
            setProductFilt(filtered)
            setCategories(productFilt.categories.filter(cat => cat.articles.length > 0).map(cat => cat.id))
        } else { 
            setProductFilt(product)
            if(window.innerWidth >= 768){
                setCategories([product.categories.find(cat => cat.articles.map(ar => ar.id).includes(item))?.id])
            } else {
                setCategories([])
            }
        }
    }

    return (
        <Container>
            <Wrapper>
                <FirstLine>
                    <LogoBlock>
                        <Back onClick={() => {
                            setProduct(undefined)
                            window.history.pushState({}, "productList", "/")
                        }} />
                        <img src={process.env.REACT_APP_API_URL + product?.image?.url} />
                    </LogoBlock>
                    <Search>
                        <input 
                            placeholder="Buscar un artículo" 
                            onChange={e => setSearchKey(e.target.value)}
                            value={searchKey}
                        />
                        { !searchKey ? <img src="https://faqcam.blacknosaur.com/uploads/magnifying_glass_260a4ccfdb.png" /> : <Close onClick={() => setSearchKey("")}>&times;</Close> }
                    </Search>
                </FirstLine>
                <Body>
                    <Dropdown 
                        categories={productFilt?.categories || []} 
                        selectedCategories={categories}
                        setSelectedCategories={setCategories} 
                        selectedItem={item}
                        setSelectedItem={setItem}
                        selectedColor={product.titleColor}
                        productSlug={product.slug}
                    />
                    <Article 
                        article={[].concat.apply([], product?.categories.map(c => c.articles)).find(ar => ar.id === item)} 
                        titleColor={product.titleColor}
                    />
                </Body>
            </Wrapper>
        </Container>
    )
}

const Wrapper = styled.div`
    width: 100%;
`

const FirstLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px){
        flex-direction: column;
    }
`
    
const LogoBlock = styled.div`
    display: flex;
    align-items: center;
    & > img {
        flex: 1;
        max-width: 250px;
        object-fit: contain;
    }
`

const Search = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    flex: 2;
    @media (max-width: 768px){
        margin-bottom: 2em;
    }
    input{
        flex: 1;
        height: 20px;
        padding: 5px;
        background-color: #f0f0f0;
        border-radius: 4px;
        border: none;
    }
    & > img{
        position: absolute;
        top: 6px;
        right: 6px;
        opacity: 0.2;
        width: 1em;
        height: 1em;
        object-fit: contain;
    }
`

const Body = styled.div`
    display: flex;
    width: 100%;
    @media (max-width: 768px){
        flex-direction: column;
    }
`

const Close = styled.span`
    position: absolute;
    top: 0px;
    right: 6px;
    font-size: 36px;
    line-height: 28px;
    cursor: pointer;
    opacity: 0.2;
`

const Back = styled.span`
    background: url(https://faqcam.blacknosaur.com/uploads/chevron_arrow_down_4195a20e7b.png);
    height: 0.8em;
    width: 0.8em;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.3;
    transform: rotate(90deg);
`

export default ProductFAQ