import React from 'react'
import styled from 'styled-components'

const Footer = () => {
    return (
        <OuterWrapper>
            <Wrapper>
                <Item href="https://www.novanet.es/materiales">Recursos</Item>
                <Item href="https://www.novanet.es/prensa">Prensa</Item>
                <Item href="https://www.novanet.es/politica-de-sig">Política de SIG</Item>
                <Item href="https://www.novanet.es/aviso-legal">Política de protección de datos</Item>
                <Item href="https://www.novanet.es"><img style={{ width: 15, height: 15, marginBottom: -3 }} src="logopeque.png"/> novanet</Item>
                <Social>
                    <Item href="https://twitter.com/Nova_Net" opacity={1} style={{ marginRight: "0.8em" }}><i className="fa fa-2x fa-twitter" /></Item>
                    <Item href="https://www.facebook.com/novanetac" opacity={1} style={{ margin: "0 0.8em" }}><i className="fa fa-2x fa-facebook" /></Item>
                    <Item href="https://www.instagram.com/novanet_ac/" opacity={1} style={{ marginLeft: "0.8em" }}><i className="fa fa-2x fa-instagram" /></Item>
                </Social>
            </Wrapper>
        </OuterWrapper>
    )
}

const OuterWrapper = styled.div`
    display: flex;
    justify-content: center;
    background-color: black;
`
const Wrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 70px 0;
    @media (max-width: 768px){
        padding: 25px 0;
    }

`
const Item = styled.a`
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: 12px;
    min-width: 30px;
    min-height: 40px;
    line-height: 40px;
    margin: 0 2em;
    opacity: ${({ opacity }) => opacity ? opacity : 0.5};
    transition: all 200ms;
    &:hover{
        opacity: 1;
    }
`

const Social = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    a{
        display: flex;
        align-items: center;
    }
    img{
        height: 30px
    }
    @media (max-width: 768px){
        img{
            height: 20px
        }
    }
`

export default Footer