import React from 'react'
import styled from 'styled-components'

const ProductSelector = ({ products, setProduct, setSelectedCategories, setSelectedItem }) => {
    return (
        <OuterWrapper>
            <Wrapper>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/t27Egd28B_A" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                { products.map((pr, i) => (
                    <Product 
                        key={"pr-"+i} 
                        image={pr?.image?.url}
                        onClick={() => {
                            const product = products.find(p => p.id === pr.id)
                            setProduct(product)
                            setSelectedCategories([product?.categories[0]?.id])
                            setSelectedItem(product?.categories[0]?.articles[0]?.id)
                            window.history.pushState({'product_id': pr.id }, pr.name, `?${pr.slug}`)
                        }}
                    />
                ))}
            </Wrapper>
        </OuterWrapper>
    )
}

const OuterWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 3em;
`

const Wrapper = styled.div`
    max-width: 720px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1em;
    h1{
        font-weight: 400;
        text-align: center;
        font-size: 2em;
        width: 100%;
        margin-top: 2em;
    }
` 

const Product = styled.div`
    width: 300px;
    height: 150px;
    border: 2px solid #f0f0f0;
    box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.05);
    transition: all 200ms;
    margin: 3em 1em 2em 1em;
    background: url(${({ image }) => process.env.REACT_APP_API_URL + image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &:hover{
        transform: scale(1.02);
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.05);
    }
    @media (max-width: 670px){
        margin: 1em;
    }
`

export default ProductSelector