import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

const Article = ({ article, titleColor }) => {
    return (
        <Wrapper>
            <Title titleColor={titleColor}>{ article?.name }</Title>
            <Content>
                <ReactMarkdown allowDangerousHtml={true}>{article?.content.replaceAll("http://207.154.229.161", "https://faqcam.blacknosaur.com")}</ReactMarkdown>
            </Content>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Title = styled.h1`
    color: ${({ titleColor }) => titleColor};
    font-weight: 400;
    margin-bottom: 1em;
`

const Content = styled.div`{
    margin-bottom: 3em;
    p{
        line-height: 1.5em;
        margin-bottom: 1.5em;
    }
    img{
        object-fit: contain;
        max-width: 100%;
    }
}`

export default Article