import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import fetchData from './utils/fetchData'
import Header from './components/Header'
import Footer from './components/Footer'
import ProductSelector from './components/ProductSelector'
import ProductFAQ from './components/ProductFAQ'
import LateralMenu from './components/LateralMenu'
import slugify from 'slugify';

const FAQ = () => {

  const [data, setData] = useState()
  const [visible, setVisible] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [selectedCategories, setSelectedCategories] = useState([])

  useEffect(() => {
    fetchData(setData)
  }, [])

  useEffect(() => {
    if(Array.isArray(data)){
      const selectedProduct = data.find(p => p.slug === window.location.search.replace("?", ""))
      if(selectedProduct){
        setSelectedProduct(selectedProduct)
        if(window.innerWidth >= 768){
          setSelectedCategories(selectedProduct.categories[0].id)
        }
        const selectedItem = [].concat.apply([], selectedProduct?.categories.map(c => c.articles))?.find(ar => slugify(ar.name, { lower: true }) === window.location.hash.replace("#", ""))?.id
        setSelectedItem(selectedItem ? selectedItem : selectedProduct?.categories[0]?.articles[0]?.id)
      }
    }

  }, [data])

  return (
    <Wrapper>
      <Header visible={visible} setVisible={setVisible} />
      <div style={{ flex: 1 }}>
        { selectedProduct ? 
          <ProductFAQ 
            product={selectedProduct} 
            setProduct={setSelectedProduct} 
            item={selectedItem} 
            setItem={setSelectedItem} 
            categories={selectedCategories} 
            setCategories={setSelectedCategories} 
          /> : 
          <ProductSelector 
            setProduct={setSelectedProduct} 
            products={Array.isArray(data) ? data : []} 
            setSelectedItem={setSelectedItem} 
            setSelectedCategories={setSelectedCategories} 
          /> 
        }
      </div>
      <Footer />
      <LateralMenu visible={visible} setVisible={setVisible} />
      <a href="https://api.whatsapp.com/send?phone=+34681023048&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre..." className="whtfloat" target="_blank">
        <i className="fa fa-whatsapp my-float"></i>
      </a>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
`

export default FAQ;
