import React, { useState } from 'react'
import styled from 'styled-components'

const Header = ({ visible, setVisible }) => {
    const [showBurger, setShowBurger] = useState(false)
    return (
        <OuterWrapper>
            <Wrapper>
                <Desktop>
                    <NavItem opacity={1} href="https://www.novanet.es/">
                        <Logo src="logocabecera.png"/>
                    </NavItem>
                    <NavWrapper>
                        <NavItem href="https://www.novanet.es/">INICIO</NavItem>
                        <NavItem href="https://www.novanet.es/productos">PRODUCTOS</NavItem>
                        <NavItem href="https://www.novanet.es/blog">NOTICIAS</NavItem>
                        <NavItem href="https://www.novanet.es/webinars">FORMACIÓN</NavItem>
                        <NavBurger 
                            onMouseEnter={() => setShowBurger(true)}
                            onMouseLeave={() => setShowBurger(false)}
                        >
                            <span>MANUALES</span>
                            { showBurger && (
                                <div className="submenu">
                                    <a href="http://ayuda.novanet.es">MANUAL INTELIGENTE</a>
                                    <a href="https://www.novanet.es/manuales">DESCARGAR MANUALES</a>
                                </div>
                            )}
                        </NavBurger>
                        <NavItem opacity={1} href="https://novanetlive.com/" fancy={true}></NavItem>
                    </NavWrapper>
                    <NavItem opacity={1} href="https://www.novanet.es/contacto">
                        <Button>CONTACTA</Button>
                    </NavItem>
                </Desktop>
                <Mobile>
                    <NavItem opacity={1} href="https://www.novanet.es/">
                        <Logo className="logoresp" src="logocabecera.png"/>
                    </NavItem>
                    <img className="lines" onClick={() => setVisible(!visible)} src="https://faqcam.blacknosaur.com/uploads/5e274bb442d658e5d3e7dceb_menu_icon_f9a7f689a2.png" />
                </Mobile>
            </Wrapper>
            <Title>
                <h1>Manual inteligente</h1>
            </Title>
        </OuterWrapper>
    )
}

const OuterWrapper = styled.div`
    width: 100vw;
    background-position: 50% 25%;
    background-image: url("inicio.jpg");
`

const Wrapper = styled.div`
    height: 64px;
    padding: 30px 50px;
`

const Logo = styled.img`
    width: 150px;
    object-fit: contain;
`

const NavWrapper = styled.div`
    display: flex;
`
    
const NavItem = styled.a`
    color: white;
    text-decoration: none;
    opacity: ${({ opacity }) => opacity ? opacity : 0.6};
    transition: all 100ms ease;
    padding: ${({ fancy }) => fancy ? "9px 0" : "20px"};
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: 500;
    width: ${({ fancy }) => fancy ? "120px" : "auto"};
    background: ${({ fancy }) => fancy ? `url("live.png")` : "none"};
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center;
    &:hover{
        color: #b6b6b6;
        opacity: 0.9;
    }
`

const NavBurger = styled.div`
    color: white;
    text-decoration: none;
    opacity: ${({ opacity }) => opacity ? opacity : 0.6};
    transition: all 100ms ease;
    padding: 20px;
    margin-right: 12px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: 500;
    width: auto;
    background: none;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center;
    position: relative;
    &:hover{
        color: #b6b6b6;
        opacity: 0.9;
    }
    &:after{
        content: "⌄";
        position: absolute;
        top: 16px;
        right: 6px;
        transform: scaleX(1.4);
    }
    .submenu{
        position: absolute;
        background-color: white;
        top: 50px;
        left: 6px;
        width: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        a{
            color: black;
            width: calc(100% - 2em);
            padding: 1em;
            text-decoration: none;
        }
    }
`

const Button = styled.a`
    opacity: 1 !important;
    padding: 12px 25px;
    background-color: #1a1b1f;
    transition: all 400ms ease;
    color: white;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    &:hover{
        background-color: #32343a;
    }
`

const Title = styled.div`
    display: flex;
    height: 180px;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px){
        height: 90px;
    }
    h1{
        color: white;
        margin: -60px 0 0 0;
        padding: 0;
        text-align: center;
        font-weight: 400;
        font-size: 2em;
    }
    @media (max-width: 768px){
        h1{
            height: 90px;
            font-size: 1.5em;
            margin: 0;
        }
    }
`

const Desktop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1150px){
        display: none;
    }
}`

const Mobile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 1150px){
        display: none;
    }
    .lines{
        filter: invert(1);
        height: 1em;
        margin-top: -6px;
    }
    .logoresp{
        width: 125px;
    }
`

export default Header