import React from 'react'
import styled from 'styled-components'

const LateralMenu = ({ visible, setVisible }) => {
    return (
       <Overlay visible={visible} onClick={e => {
            if(!e.nativeEvent.path.find(elem => elem.classList?.contains("menu"))){ setVisible(false) } //Para que se cierre si pinchas fuera del menú
       }}>
            <Menu className="menu" visible={visible}>
                <Top>
                    <NavItem href="https://www.novanet.es/">INICIO</NavItem>
                    <NavItem href="https://www.novanet.es/productos">PRODUCTOS</NavItem>
                    <NavItem href="https://www.novanet.es/blog">NOTICIAS</NavItem>
                    <NavItem href="https://www.novanet.es/webinars">FORMACIÓN</NavItem>
                    <NavItem href="http://ayuda.novanet.es">MANUAL INTELIGENTE</NavItem>
                    <NavItem href="https://www.novanet.es/manuales">DESCARGAR MANUALES</NavItem>
                    <NavItem opacity={1} marginTop="1em" href="https://novanetlive.com/" fancy={true}></NavItem>
                </Top>
                <Bottom>
                    <NavItem opacity={1} marginTop="-1em" href="https://www.novanet.es/contacto">
                        <Button>CONTACTA</Button>
                    </NavItem>
                </Bottom>
            </Menu>
       </Overlay> 
    )
}

const Overlay = styled.div`
    width: ${({ visible }) => visible ? "100vw" : "0px"};
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 0;
    margin: 0;
`

const Menu = styled.div`
    height: 100%;
    width: ${({ visible }) => visible ? "250px" : "0px"};
    position: absolute;
    top: 0;
    right: -200px;
    background-color: red;
    z-index: 1000;
    transition: transform 300ms;
    transform: translateX(${({ visible }) => visible ? "-200px" : "0px"});
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background-color: white;
    box-shadow: ${({ visible }) => visible ? "0 0 10px 2px rgba(0, 0, 0, 0.1)" : "none"};
`

const Top = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0.5em;
`

const Bottom = styled.div`
    display: flex;
    padding-bottom: 1em;
`

const NavItem = styled.a`
    color: black;
    text-decoration: none;
    opacity: ${({ opacity }) => opacity ? opacity : 0.6};
    transition: all 100ms ease;
    padding: ${({ fancy }) => fancy ? "12px 0px 12px 9px" : "20px"};
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    font-weight: 500;
    width: ${({ fancy }) => fancy ? "150px" : "auto"};
    background: ${({ fancy }) => fancy ? `url("live.png")` : "none"};
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    background-position: center;
    margin: ${({ marginTop = 0 }) => marginTop} 0 0 1em;
    &:hover{
        color: #b6b6b6;
        opacity: 0.9;
    }
`

const Button = styled.div`
    opacity: 1 !important;
    padding: 12px 25px;
    background-color: #1a1b1f;
    transition: all 400ms ease;
    color: white;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    &:hover{
        background-color: #32343a;
    }
`

export default LateralMenu
