const fetchData = async (setData) => {
    const resp = await fetch("https://faqcam.blacknosaur.com/graphql", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
        query: `{
            products(where: { published: true }){
                id
                name
                slug
                description
                titleColor
                image{
                    url
                }
                categories(where: { published: true }){
                    id
                    name
                    image{
                        url
                    }
                    description
                    articles(where: { published: true }){
                        id
                        name
                        content
                        image{
                            url
                        }
                        media{
                            url
                        }
                    }
                }
            }
        }`
        })
    })
    const data = await resp.json()
    setData(data.data.products)
}

export default fetchData