import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import slugify from 'slugify'

const Dropdown = ({ 
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedItem,
    setSelectedItem,
    selectedColor,
    productSlug
}) => {
    return (
        <Wrapper>
        { categories.map((c, i) => {
            return (
                <Category key={"category-"+i}>
                    <CategoryTitle onClick={() => setSelectedCategories(selectedCategories.includes(c.id) ? selectedCategories.filter(cat => cat !== c.id) : [c.id])}>
                        <span>{c.name}</span>
                        <Icon selected={selectedCategories === c.id}/>
                    </CategoryTitle>
                    <CategoryBody 
                        selected={selectedCategories.includes(c.id)} 
                        n={Array.isArray(c.articles) ? c.articles.length : 0}
                    >
                        { c.articles.map((it, j) => {
                            return (
                                <CategoryItem
                                    selected={selectedItem === it.id}
                                    onClick={() => {
                                        setSelectedItem(it.id)
                                        window.history.pushState({'article_id': it.id }, it.name, `?${productSlug}#${slugify(it.name, { lower: true })}`)
                                    }}
                                    key={`item-${i}-${j}`}
                                    selectedColor={selectedColor}
                                >
                                    <span>{it.name}</span>
                                </CategoryItem>
                            )
                        })}
                    </CategoryBody>
                </Category>

            )
        })}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex: 0;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    @media (min-width: 768px){
        margin-right: 3em;
        margin-bottom: 3em;
    }
`

const Category = styled.div`
    width: 100%
`

const CategoryTitle = styled.div`
    cursor: pointer;
    display: flex;
    margin-bottom: 1em;
    @media (max-width: 768px){
        margin-bottom: 0.5em;
    }
    align-items: center;
    justify-content: center;
    span{
        width: 200px;
        margin-right: 1em;
        line-height: 1.4em;
        @media (max-width: 768px){
            font-size: 0.8em;
        }
    }
`

const CategoryBody = styled.div`
    display: flex;
    flex-direction: column;
    transition: all 200ms;
    height: ${({ selected, n }) => selected ? 60*n + "px" : "0px"};
    @media (max-width: 768px){
        height: ${({ selected, n }) => selected ? 31*n + "px" : "0px"};
    }
    overflow: hidden;
`

const CategoryItem = styled.span`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 2em;
    font-size: 0.9em;
    opacity: 0.7;
    height: 54px;
    margin-bottom: 6px;
    color: ${({ selected, selectedColor }) => selected ? selectedColor : "black"};
    &:last-child{
        margin-bottom: 1.5em;
    }
    @media (max-width: 768px){
        display: flex;
        justify-content: center;
        height: 25px;
        font-size: 0.8em;
        margin-left: 0;
        &:last-child{
            margin-bottom: 1em;
        }
    }
    &:hover{
        opacity: 0.9;
    }
`

const Icon = styled.div`
    background: url("https://faqcam.blacknosaur.com/uploads/chevron_arrow_down_4195a20e7b.png");
    height: 1em;
    width: 1em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.5;
    transform: rotate(${({ selected }) => selected ? "180deg" : "0deg" });
    transition: all 200ms;
`

export default Dropdown